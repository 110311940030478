(function (w, d, searxng) {
  "use strict";
  function initializeTopSites () {
    const oldTopSitesContainer = d.querySelector(".topsites-container");
    oldTopSitesContainer?.remove();

    const newTabPageHandler = searxng.getNewTabPageHandler();
    if (!newTabPageHandler?.mostVisitedAvailable) {
      return;
    }

    const topSitesContainer = d.createElement("div");
    topSitesContainer.classList.add("topsites-container");

    if (searxng.newTabSettings.hidePopularSites) {
      topSitesContainer.style.display = "none";
    }

    const mostVisitedWebsites = newTabPageHandler?.mostVisited ?? [];
    for (const { rid, faviconUrl } of mostVisitedWebsites) {
      const topSiteContainer = d.createElement("div");
      topSiteContainer.classList.add("topsites-entry");
      topSitesContainer.appendChild(topSiteContainer);

      const faviconContainer = d.createElement("span");
      faviconContainer.classList.add("topsites-favicon-container");
      if (searxng.newTabConfig.topSitesIconSize) {
        faviconContainer.style.setProperty("--icon-size", searxng.newTabConfig.topSitesIconSize);
      }
      const favicon = d.createElement("img");
      favicon.src = faviconUrl;
      favicon.classList.add("topsites-favicon");
      faviconContainer.appendChild(favicon);
      topSiteContainer.appendChild(faviconContainer);

      const themesConfig = searxng.newTabConfig.settings.find(({ type, name }) => type === "select" && name === "layout");
      const currentLayout = themesConfig?.options?.find(({ value }) => value === searxng.newTabSettings.layout?.value) ?? themesConfig?.options?.default;
      const isLightMode = currentLayout?.theme === "light";
      const iframeBaseUrl = `chrome-search://most-visited/title.html?rid=${rid}`;

      const topSiteTextIframe = d.createElement("iframe");
      const topSiteIframeUrl = new URL(iframeBaseUrl);
      const topSiteIframeUrlParams = {
        c: isLightMode ? "000000ff" : "ffffffff",
        f: "sans-serif",
        fs: "12",
        pos: "10",
        ta: "center",
      };
      for (const [key, value] of Object.entries(topSiteIframeUrlParams)) {
        topSiteIframeUrl.searchParams.append(key, value);
      }
      topSiteTextIframe.src = topSiteIframeUrl.toString();
      topSiteTextIframe.classList.add("topsites-text-iframe");
      topSiteContainer.appendChild(topSiteTextIframe);

      const linkIframe = d.createElement("iframe");
      const linkIframeUrl = new URL(iframeBaseUrl);
      linkIframeUrl.searchParams.append("fs", "0");
      linkIframe.src = linkIframeUrl.toString();
      linkIframe.classList.add("topsites-link-iframe");
      topSiteContainer.appendChild(linkIframe);
    }

    const searchBar = d.getElementById("search");
    searchBar.insertAdjacentElement("afterend", topSitesContainer);
  }

  initializeTopSites();
  searxng.initializeTopSites = initializeTopSites;
})(window, document, window.searxng);
