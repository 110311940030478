(function (w, d, searxng) {
  "use strict";
  let newTabConfig = {
    searchBarText: "Search for...",
    settingsIconSize: "32px",
    hideTopSitesByDefault: false,
    topSitesIconSize: "53px",
    defaultSettings: {
      hidePopularSites: false,
      layout: {
        value: "light",
        text: "Light Mode",
        theme: "light",
        default: true,
      },
      searchProvider: { value: "default", text: "Default" },
    },
    settings: [
      {
        name: "searchProvider",
        type: "select",
        label: "Search Provider",
        cookieName: "GIVERO_SEARCH_PROVIDER",
        options: [
          { value: "default", text: "Default", default: true },
          { value: "givero", text: "Givero" },
          { value: "bing", text: "Bing" },
          { value: "yahoo", text: "Yahoo" },
          { value: "google", text: "Google" },
        ],
      },
      {
        name: "layout",
        type: "select",
        label: "Layout",
        cookieName: "background",
        options: [
          {
            value: "light",
            text: "Light Mode",
            theme: "light",
            default: true,
          },
          { value: "dark", text: "Dark Mode", theme: "dark" },
          { value: "ocean", text: "Ocean", theme: "dark" },
          { value: "forest", text: "Forest", theme: "dark" },
          { value: "desert", text: "Desert", theme: "light" },
          { value: "snow", text: "Snow", theme: "light" },
        ],
      },
      {
        name: "hidePopularSites",
        type: "toggle",
        label: "Hide Popular Sites",
        cookieName: "hide_popular_sites",
      },
    ]
  };

  function replaceFeatureFlags (currentConfig) {
    const featureFlags = "$$$FLAGS$$$";
    const areParamsReplaced = !(featureFlags.startsWith && featureFlags.startsWith("$"));
    return areParamsReplaced ? { ...currentConfig, ...featureFlags } : currentConfig;
  }

  function getNewTabPageHandler () {
    if (w.IS_DEV) {
      return { mostVisitedAvailable: true };
    }
    return w.chrome?.embeddedSearch?.newTabPage;
  }

  newTabConfig = replaceFeatureFlags(newTabConfig);

  searxng.newTabConfig = newTabConfig;
  searxng.newTabSettings = newTabConfig.defaultSettings;
  searxng.getNewTabPageHandler = getNewTabPageHandler;
})(window, document, window.searxng);
