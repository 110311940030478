(function (w, d, searxng) {
  "use strict";
  if (searxng.newTabConfig.searchBarText) {
    d.getElementById("q").placeholder = searxng.newTabConfig.searchBarText;
  }
  const hamburgerMenuButton = d.querySelector(".hamburger-menu");
  const settingsDrawer = d.querySelector(".settings-drawer");
  const closeMenuButton = d.querySelector(".hamburger-menu-close");

  if (searxng.newTabConfig.settingsIconSize) {
    hamburgerMenuButton.style.setProperty("--icon-size", searxng.newTabConfig.settingsIconSize);
  }

  searxng.ready(function () {
    function toggleSettingsDrawer () {
      settingsDrawer.classList.toggle("active");
      searxng.sendEvent("settings_drawer_clicked", { isOpen: settingsDrawer.classList.contains("active") });
    }

    function setupHamburgerMenu () {
      if (!hamburgerMenuButton || !settingsDrawer) {
        return;
      }

      searxng.addClickAndKeyboardListener(hamburgerMenuButton, toggleSettingsDrawer);
      searxng.addClickAndKeyboardListener(closeMenuButton, toggleSettingsDrawer);

      d.addEventListener("click", (event) => {
        if (event.target === hamburgerMenuButton) {
          return;
        }

        if (!settingsDrawer.contains(event.target) && settingsDrawer.classList.contains("active")) {
          toggleSettingsDrawer();
        }
      });

      initializeSettingsControls();
    }

    function hideWhenNoExtension () {
      const { mostVisitedAvailable } = searxng.getNewTabPageHandler() ?? {};
      hamburgerMenuButton.style.opacity = mostVisitedAvailable ? 1 : 0;
      hamburgerMenuButton.style.display = mostVisitedAvailable ? "block" : "none";
      settingsDrawer.style.opacity = mostVisitedAvailable ? 1 : 0;
      settingsDrawer.style.display = mostVisitedAvailable ? "flex" : "none";
    }

    function saveAllSettings (initial = false) {
      for (const setting of searxng.newTabConfig.settings) {
        saveSetting(
          setting,
          initial
        );
      }
    }

    function saveSetting (setting, initial = false) {
      const { name: settingName } = setting;
      const { cookieName } = searxng.newTabConfig.settings.find(({ name }) => name === settingName);

      switch (settingName) {
      case "layout": {
        const { theme, value: background } = setting ?? {};
        if (theme) {
          setCookie("simple_style", theme);
        }
        if (background) {
          setCookie("background", background);
        }
        break;
      }
      case "searchProvider": {
        if (initial) {
          const currentCookieValue = getCookie(cookieName);
          if (!currentCookieValue) {
            return;
          }
        }
        const { value: searchProvider } = searxng.newTabSettings.searchProvider ?? {};
        if (searchProvider) {
          setSearchProvider(searchProvider);
        }
        break;
      }
      case "hidePopularSites": {
        setCookie(cookieName, !!searxng.newTabSettings.hidePopularSites);
      }
      }
    }

    function setSearchProvider (searchProvider) {
      const searchProviderInput = d.querySelector("#search_provider");
      searchProviderInput.value = searchProvider;
      const searchProviderContainer = d.querySelector("[data-select-id='searchProvider']");
      if (!searchProviderContainer) {
        return;
      }
      const searchProviderOptions = searchProviderContainer.querySelector(".custom-options");
      const defaultOption = Array.from(searchProviderOptions?.children)?.find(({ textContent: text }) => text.toLowerCase() === "default");
      defaultOption?.remove();

      if (searchProvider !== "default") {
        const searchProviderSettings = searxng.newTabConfig.settings.find(({ name }) => name === "searchProvider");
        setCookie(searchProviderSettings.cookieName, searchProvider, false);
      }
    }

    function getCookie (cookieName) {
      const cookies = d.cookie.split(";").map((cookie) => cookie.trim().split("="));
      const [, cookieValue] = cookies?.find(([name]) => name === cookieName) ?? [];
      return cookieValue;
    }

    function setCookie (cookieName, cookieValue, currentPathOnly = true) {
      d.cookie = `${cookieName}=${cookieValue}; max-age=34560000; domain=.${d.location.hostname}; ${
        currentPathOnly ? "path=/new_tab;" : ""
      }`;
    }

    function loadCookieSettings () {
      for (const setting of searxng.newTabConfig.settings) {
        const cookieValue = getCookie(setting.cookieName);
        switch (setting.name) {
        case "hidePopularSites":
          if (cookieValue == null) {
            if (searxng.newTabConfig[setting.name]) {
              searxng.newTabSettings[setting.name] = searxng.newTabConfig.hideTopSitesByDefault;
            }
          } else {
            searxng.newTabSettings[setting.name] = cookieValue === "true";
          }
          break;
        case "layout":
        case "searchProvider": {
          const selectOptions = searxng.newTabConfig.settings.find(({ name }) => name === setting.name).options;
          const cookieSelection = selectOptions?.find(({ value: selectValue }) => selectValue === cookieValue);
          if (cookieSelection) {
            searxng.newTabSettings[setting.name] = cookieSelection;
          }
          break;
        }
        }
      }
    }

    function applyAllSettings () {
      for (const [settingName, settingValue] of Object.entries(searxng.newTabSettings)) {
        applySetting(settingName, settingValue);
      }
    }

    function applySetting (settingName, settingValue) {
      if (settingValue == null) {
        return;
      }
      const settingElement = settingsDrawer.querySelector(`[data-select-id="${settingName}"]`);
      if (settingElement) {
        const selectedSettingType = searxng.newTabConfig.settings.find(({ name }) => name === settingName)?.type;
        switch (selectedSettingType) {
        case "select": {
          const currentSelect = settingsDrawer.querySelector(`[data-select-id="${settingName}"]`);
          const selectOptions = currentSelect?.querySelectorAll(".custom-option");
          for (const option of selectOptions) {
            if (option.textContent === settingValue.text) {
              currentSelect.querySelector(".custom-select-trigger").textContent = settingValue.text;
              option.classList.add("selected");
              continue;
            }
            option.classList.remove("selected");
          }

          if (settingName === "layout") {
            const themeBg = Array.from(d.documentElement.classList).find((className) => className.startsWith("bg-"));
            if (themeBg) {
              d.documentElement.classList.replace(themeBg, `bg-${settingValue.value}`);
            } else {
              d.documentElement.classList.add(`bg-${settingValue.value}`);
            }
            const themeClass = Array.from(d.documentElement.classList).find((className) => className.startsWith("theme-"));
            if (themeClass) {
              d.documentElement.classList.replace(themeClass, `theme-${settingValue.theme}`);
            } else {
              d.documentElement.classList.add(`theme-${settingValue.theme}`);
            }
            searxng.initializeTopSites();
          } else if (settingName === "searchProvider") {
            setSearchProvider(settingValue.value);
          }
          break;
        }
        case "toggle": {
          settingElement.classList.toggle("active", settingValue);
          setToggle(settingName, settingValue);
          break;
        }
        }
      }
    }

    function setToggle (settingName, settingValue) {
      switch (settingName) {
      case "hidePopularSites": {
        const topSitesContainer = d.querySelector(".topsites-container");
        if (topSitesContainer) {
          topSitesContainer.style.display = settingValue ? "none" : "flex";
        }
        break;
      }
      }
    }

    function initializeSettingsControls () {
      for (const setting of searxng.newTabConfig.settings) {
        const { name: settingName, type: settingType, label: settingLabel, options: settingOptions } = setting;
        const settingsPanel = settingsDrawer.querySelector(".settings");
        const settingContainer = d.createElement("div");
        settingContainer.classList.add("setting-container", settingType);
        const label = d.createElement("span");
        label.textContent = settingLabel;
        label.classList.add("setting-label");

        switch (settingType) {
        case "toggle": {
          addToggle(settingName, settingContainer, label);
          settingsPanel.appendChild(settingContainer);
          break;
        }
        case "select": {
          addSelect(settingName, settingContainer, label, settingOptions);
          settingsPanel.appendChild(settingContainer);
          searxng.customSelect(`.custom-select-wrapper[data-select-id="${settingName}"]`);
          break;
        }
        }
      }
    }

    function updateSettingValue (settingName, newSettingValue, saveCookie = false) {
      searxng.newTabSettings[settingName] = newSettingValue;
      if (saveCookie) {
        saveSetting({
          name: settingName,
          ...newSettingValue
        });
      }
      applySetting(settingName, newSettingValue);
    }

    function refreshToggles () {
      for (const toggle of settingsDrawer.querySelectorAll(".toggle-switch")) {
        searxng.newTabSettings[toggle.dataset.selectId] = toggle.classList.contains("active");

        searxng.addClickAndKeyboardListener(toggle, function () {
          updateSettingValue(toggle.dataset.selectId, toggle.classList.toggle("active"), true);
        });
      }
    }

    function addSelect (settingName, settingContainer, label, settingOptions) {
      const selectWrapper = d.createElement("div");
      selectWrapper.classList.add("custom-select-wrapper");
      selectWrapper.dataset.selectId = settingName;

      const customSelect = d.createElement("div");
      customSelect.classList.add("custom-select");
      customSelect.tabIndex = 0;
      customSelect.addEventListener("click", () => {
        searxng.sendEvent(`${settingName}_clicked`, { isOpen: !customSelect.classList.contains("open") });
      });
      selectWrapper.appendChild(customSelect);

      const customSelectTrigger = d.createElement("span");
      customSelectTrigger.classList.add("custom-select-trigger");
      customSelect.appendChild(customSelectTrigger);

      const chevron = d.createElement("span");
      chevron.classList.add("chevron");
      customSelect.appendChild(chevron);

      const customOptions = d.createElement("div");
      customOptions.classList.add("custom-options");
      customSelect.appendChild(customOptions);

      const selectInput = d.createElement("input");
      selectInput.type = "hidden";
      selectInput.name = settingName;
      selectInput.id = settingName;

      for (const option of settingOptions) {
        const customOption = d.createElement("span");
        customOption.classList.add("custom-option");
        if (option.default) {
          customSelectTrigger.textContent = option.text;
          updateSettingValue(settingName, option);
        }
        customOption.tabIndex = 0;
        customOption.textContent = option.text;
        customOptions.appendChild(customOption);

        searxng.addClickAndKeyboardListener(customOption, function () {
          updateSettingValue(settingName, option, true);
          searxng.sendEvent(`${settingName}_selected_option`, { selected: option.value });
        });
      }

      settingContainer.appendChild(label);
      settingContainer.appendChild(selectWrapper);
      settingContainer.appendChild(selectInput);
    }

    function addToggle (settingName, settingContainer, label) {
      const toggle = d.createElement("div");
      toggle.classList.add("toggle-switch");
      toggle.classList.toggle("active", searxng.newTabSettings[settingName]);
      toggle.dataset.selectId = settingName;
      toggle.tabIndex = 0;
      const toggleKnob = d.createElement("span");
      toggleKnob.classList.add("toggle-knob");
      toggleKnob.name = "popular-sites-toggle";
      toggle.appendChild(toggleKnob);
      toggle.addEventListener("click", () => {
        searxng.sendEvent(`${settingName}_clicked`, { isActive: !toggle.classList.contains("active") });
      });
      settingContainer.appendChild(toggle);
      settingContainer.appendChild(label);
    }

    function init (newTabPageHandler) {
      if (!newTabPageHandler) {
        hideWhenNoExtension();
        return;
      }
      newTabPageHandler.onmostvisitedchange = undefined;
      hideWhenNoExtension();
      setupHamburgerMenu();
      loadCookieSettings();
      applyAllSettings();
      refreshToggles();
      searxng.initializeTopSites();
      saveAllSettings(true);
    }

    if (!hamburgerMenuButton || !settingsDrawer) {
      return;
    }

    const newTabPageHandler = searxng.getNewTabPageHandler();
    if (newTabPageHandler?.mostVisitedAvailable) {
      init(newTabPageHandler);
    } else if (newTabPageHandler) {
      newTabPageHandler.onmostvisitedchange = () => init(newTabPageHandler);
    } else {
      hideWhenNoExtension();
    }
  });
})(window, document, window.searxng);
